import * as React from "react"
import { Link } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"

import { useState } from "react";
import "./style.css";
let json_current = require("assets/renal.json");


function checkAnswer(option, correctAnswer) {
  if (option === correctAnswer) {
    console.log("Correct");
    return true;
  } else {
    console.log("Incorrect");
    return false;
  }
}

function Foo(props) {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div className="columns is-centered padding-md">
    <div className="column is-12-mobile is-11-tablet is-10-desktop is-8-widescreen">
      <p className="i-gradient is-size-3 global-text has-text-weight-bold">
        MS2 - DMC
      </p>
      <p
        className="is-size-2-desktop is-size-3-tablet is-size-4-mobile"
        style={{ padding: "0.5em 0 0 0", lineHeight: "2em" }}
      >
        <span
          className="has-text-weight-bold"
          style={{
            background: "linear-gradient(90deg, #366395, #9C9C62)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }}
        >
          Neuroscience
        </span>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Anatomy{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cerebellum-anatomy"
          to="/mcqs/tagged/cerebellum-anatomy"
        >
          Cerebellum anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/brainstem-anatomy"
          to="/mcqs/tagged/brainstem-anatomy"
        >
          Brainstem anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cerebrum-anatomy"
          to="/mcqs/tagged/cerebrum-anatomy"
        >
          Cerebrum anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/thalamus-anatomy"
          to="/mcqs/tagged/thalamus-anatomy"
        >
          Thalamus anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/hypothalamus-anatomy"
          to="/mcqs/tagged/hypothalamus-anatomy"
        >
          Hypothalamus anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/limbic-system-anatomy"
          to="/mcqs/tagged/limbic-system-anatomy"
        >
          Limbic system anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/autonomic-nervous-system"
          to="/mcqs/tagged/autonomic-nervous-system"
        >
          Autonomic nervous system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/ventricular-system"
          to="/mcqs/tagged/ventricular-system"
        >
          Ventricular system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/basal-ganglia-anatomy"
          to="/mcqs/tagged/basal-ganglia-anatomy"
        >
          Basal ganglia anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/blood-supply-of-brain-and-spinal-cord"
          to="/mcqs/tagged/blood-supply-of-brain-and-spinal-cord"
        >
          Blood supply of brain and spinal cord -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cranial-nerves-and-their-central-connections"
          to="/mcqs/tagged/cranial-nerves-and-their-central-connections"
        >
          Cranial nerves and their central connections -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/spinal-cord-anatomy"
          to="/mcqs/tagged/spinal-cord-anatomy"
        >
          Spinal cord anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/meninges"
          to="/mcqs/tagged/meninges"
        >
          Meninges -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/organization-of-nervous-system"
          to="/mcqs/tagged/organization-of-nervous-system"
        >
          Organization of nervous system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/blood-brain-barrier"
          to="/mcqs/tagged/blood-brain-barrier"
        >
          Blood brain barrier -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pineal-gland-anatomy"
          to="/mcqs/tagged/pineal-gland-anatomy"
        >
          Pineal gland anatomy -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Histology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/structure-of-neuron-and-neuroglia"
          to="/mcqs/tagged/structure-of-neuron-and-neuroglia"
        >
          Structure of neuron and neuroglia -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-cerebral-cortex"
          to="/mcqs/tagged/histology-of-cerebral-cortex"
        >
          Histology of cerebral cortex -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-cerebellar-cortex"
          to="/mcqs/tagged/histology-of-cerebellar-cortex"
        >
          Histology of cerebellar cortex -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-spinal-cord"
          to="/mcqs/tagged/histology-of-spinal-cord"
        >
          Histology of spinal cord -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Embryology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-nervous-system-and-meninges"
          to="/mcqs/tagged/development-of-nervous-system-and-meninges"
        >
          Development of nervous system and meninges -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-brain"
          to="/mcqs/tagged/development-of-brain"
        >
          Development of brain -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-spinal-cord"
          to="/mcqs/tagged/development-of-spinal-cord"
        >
          Development of spinal cord -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/birth-defects-of-brain"
          to="/mcqs/tagged/birth-defects-of-brain"
        >
          Birth defects of brain -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Biochemistry{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/biochemistry-of-neurotransmitters"
          to="/mcqs/tagged/biochemistry-of-neurotransmitters"
        >
          Biochemistry of neurotransmitters -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/brain-needs-in-different-metabolic-states"
          to="/mcqs/tagged/brain-needs-in-different-metabolic-states"
        >
          Brain needs in different metabolic states -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/serotonin-and-pain"
          to="/mcqs/tagged/serotonin-and-pain"
        >
          Serotonin and pain -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/free-radicals"
          to="/mcqs/tagged/free-radicals"
        >
          Free radicals -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/neurolipids"
          to="/mcqs/tagged/neurolipids"
        >
          Neurolipids -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Biochemistry{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/biochemistry-of-neurotransmitters"
          to="/mcqs/tagged/biochemistry-of-neurotransmitters"
        >
          Biochemistry of neurotransmitters -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/brain-needs-in-different-metabolic-states"
          to="/mcqs/tagged/brain-needs-in-different-metabolic-states"
        >
          Brain needs in different metabolic states -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/serotonin-and-pain"
          to="/mcqs/tagged/serotonin-and-pain"
        >
          Serotonin and pain -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/free-radicals"
          to="/mcqs/tagged/free-radicals"
        >
          Free radicals -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/neurolipids"
          to="/mcqs/tagged/neurolipids"
        >
          Neurolipids -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Physiology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/motor-functions-of-spinal-cord"
          to="/mcqs/tagged/motor-functions-of-spinal-cord"
        >
          Motor functions of spinal cord -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cortical-and-brainstem-control-of-motor-function"
          to="/mcqs/tagged/cortical-and-brainstem-control-of-motor-function"
        >
          Cortical and brainstem control of motor function -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/role-of-cerebellum-and-basal-ganglia-in-motor-control"
          to="/mcqs/tagged/role-of-cerebellum-and-basal-ganglia-in-motor-control"
        >
          Role of cerebellum and basal ganglia in motor control -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cerebral-cortex-learning-and-memory"
          to="/mcqs/tagged/cerebral-cortex-learning-and-memory"
        >
          Cerebral cortex learning and memory -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/limbic-system-and-hypothalamus"
          to="/mcqs/tagged/limbic-system-and-hypothalamus"
        >
          Limbic system and hypothalamus -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/sleep"
          to="/mcqs/tagged/sleep"
        >
          Sleep -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/functions-of-autonomic-nervous-system"
          to="/mcqs/tagged/functions-of-autonomic-nervous-system"
        >
          Functions of autonomic nervous system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cerebral-blood-flow-and-brain-metabolism"
          to="/mcqs/tagged/cerebral-blood-flow-and-brain-metabolism"
        >
          Cerebral blood flow and brain metabolism -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/neuron-membrane-potential"
          to="/mcqs/tagged/neuron-membrane-potential"
        >
          Neuron membrane potential -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/types-classifications-and-functions-of-nerve-fibers"
          to="/mcqs/tagged/types-classifications-and-functions-of-nerve-fibers"
        >
          Types classifications and functions of nerve fibers -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/somatic-sensations-and-sensory-receptors"
          to="/mcqs/tagged/somatic-sensations-and-sensory-receptors"
        >
          Somatic sensations and sensory receptors -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/organization-of-ascending-and-descending-pathways"
          to="/mcqs/tagged/organization-of-ascending-and-descending-pathways"
        >
          Organization of ascending and descending pathways -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/physiology-of-cerebrospinal-fluid"
          to="/mcqs/tagged/physiology-of-cerebrospinal-fluid"
        >
          Physiology of cerebrospinal fluid -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/physiology-of-neurotransmitters"
          to="/mcqs/tagged/physiology-of-neurotransmitters"
        >
          Physiology of neurotransmitters -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/physiology-of-pain"
          to="/mcqs/tagged/physiology-of-pain"
        >
          Physiology of pain -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pathology{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cellular-pathology"
          to="/mcqs/tagged/cellular-pathology"
        >
          Cellular pathology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/developmental-disorders-of-brain"
          to="/mcqs/tagged/developmental-disorders-of-brain"
        >
          Developmental disorders of brain -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/perinatal-brain-injury"
          to="/mcqs/tagged/perinatal-brain-injury"
        >
          Perinatal brain injury -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/brain-trauma"
          to="/mcqs/tagged/brain-trauma"
        >
          Brain trauma -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cerebral-edema-hydrocephalus-raised-intracranial-pressure-herniation"
          to="/mcqs/tagged/cerebral-edema-hydrocephalus-raised-intracranial-pressure-herniation"
        >
          Cerebral edema hydrocephalus raised intracranial pressure herniation
          -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cerebrovascular-disease"
          to="/mcqs/tagged/cerebrovascular-disease"
        >
          Cerebrovascular disease -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/infections-of-central-nervous-system"
          to="/mcqs/tagged/infections-of-central-nervous-system"
        >
          Infections of central nervous system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/neurodegenerative-diseases"
          to="/mcqs/tagged/neurodegenerative-diseases"
        >
          Neurodegenerative diseases -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pharmacology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/anticoagulant-drugs"
          to="/mcqs/tagged/anticoagulant-drugs"
        >
          Anticoagulant drugs -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/opioid-analgesics-and-antagonists"
          to="/mcqs/tagged/opioid-analgesics-and-antagonists"
        >
          Opioid analgesics and antagonists -&gt;
        </Link>
      </p>
      <p
        className="is-size-2-desktop is-size-3-tablet is-size-4-mobile"
        style={{ padding: "0.5em 0 0 0", lineHeight: "2em" }}
      >
        <span
          className="has-text-weight-bold"
          style={{
            background: "linear-gradient(90deg, #366395, #9C9C62)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }}
        >
          Head and Neck
        </span>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Anatomy{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/skull-and-cranial-cavity"
          to="/mcqs/tagged/skull-and-cranial-cavity"
        >
          Skull and cranial cavity -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/face"
          to="/mcqs/tagged/face"
        >
          Face -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/scalp"
          to="/mcqs/tagged/scalp"
        >
          Scalp -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/orbit"
          to="/mcqs/tagged/orbit"
        >
          Orbit -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/ear"
          to="/mcqs/tagged/ear"
        >
          Ear -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/temporal-fossa"
          to="/mcqs/tagged/temporal-fossa"
        >
          Temporal fossa -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/infratemporal-fossa"
          to="/mcqs/tagged/infratemporal-fossa"
        >
          Infratemporal fossa -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pterygopalatine-fossa"
          to="/mcqs/tagged/pterygopalatine-fossa"
        >
          Pterygopalatine fossa -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/neck"
          to="/mcqs/tagged/neck"
        >
          Neck -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pharynx"
          to="/mcqs/tagged/pharynx"
        >
          Pharynx -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/larynx"
          to="/mcqs/tagged/larynx"
        >
          Larynx -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/nasal-and-paranasal-cavities"
          to="/mcqs/tagged/nasal-and-paranasal-cavities"
        >
          Nasal and paranasal cavities -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/anatomy-of-oral-cavity"
          to="/mcqs/tagged/anatomy-of-oral-cavity"
        >
          Anatomy of oral cavity -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/anatomy-of-hard-and-soft-palate"
          to="/mcqs/tagged/anatomy-of-hard-and-soft-palate"
        >
          Anatomy of hard and soft palate -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/anatomy-of-salivary-glands"
          to="/mcqs/tagged/anatomy-of-salivary-glands"
        >
          Anatomy of salivary glands -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/anatomy-of-tongue"
          to="/mcqs/tagged/anatomy-of-tongue"
        >
          Anatomy of tongue -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/blood-supply-of-head-and-neck"
          to="/mcqs/tagged/blood-supply-of-head-and-neck"
        >
          Blood supply of head and neck -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/nerve-supply-of-head-and-neck"
          to="/mcqs/tagged/nerve-supply-of-head-and-neck"
        >
          Nerve supply of head and neck -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/lymphatics-of-head-and-neck"
          to="/mcqs/tagged/lymphatics-of-head-and-neck"
        >
          Lymphatics of head and neck -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cranial-nerves-and-their-central-connections"
          to="/mcqs/tagged/cranial-nerves-and-their-central-connections"
        >
          Cranial nerves and their central connections -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cervical-plexus"
          to="/mcqs/tagged/cervical-plexus"
        >
          Cervical plexus -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/vertebral-column"
          to="/mcqs/tagged/vertebral-column"
        >
          Vertebral column -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cervical-vertebrae-anatomy"
          to="/mcqs/tagged/cervical-vertebrae-anatomy"
        >
          Cervical vertebrae anatomy -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Histology{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-eye"
          to="/mcqs/tagged/histology-of-eye"
        >
          Histology of eye -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-ear"
          to="/mcqs/tagged/histology-of-ear"
        >
          Histology of ear -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-oral-cavity"
          to="/mcqs/tagged/histology-of-oral-cavity"
        >
          Histology of oral cavity -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-salivary-glands"
          to="/mcqs/tagged/histology-of-salivary-glands"
        >
          Histology of salivary glands -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-nasal-cavities-nasophyrnx-larynx-trachea"
          to="/mcqs/tagged/histology-of-nasal-cavities-nasophyrnx-larynx-trachea"
        >
          Histology of nasal cavities nasophyrnx larynx trachea -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Physiology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/optics-of-vision"
          to="/mcqs/tagged/optics-of-vision"
        >
          Optics of vision -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/receptor-and-neural-function-of-retina"
          to="/mcqs/tagged/receptor-and-neural-function-of-retina"
        >
          Receptor and neural function of retina -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/neurophysiology-of-vision"
          to="/mcqs/tagged/neurophysiology-of-vision"
        >
          Neurophysiology of vision -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/sense-of-hearing"
          to="/mcqs/tagged/sense-of-hearing"
        >
          Sense of hearing -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/sense-of-taste"
          to="/mcqs/tagged/sense-of-taste"
        >
          Sense of taste -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/sense-of-smell"
          to="/mcqs/tagged/sense-of-smell"
        >
          Sense of smell -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Embryology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-pharyngeal-apparatus"
          to="/mcqs/tagged/development-of-pharyngeal-apparatus"
        >
          Development of pharyngeal apparatus -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-tongue"
          to="/mcqs/tagged/development-of-tongue"
        >
          Development of tongue -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-face"
          to="/mcqs/tagged/development-of-face"
        >
          Development of face -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-nasal-cavities"
          to="/mcqs/tagged/development-of-nasal-cavities"
        >
          Development of nasal cavities -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-palate"
          to="/mcqs/tagged/development-of-palate"
        >
          Development of palate -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-ear"
          to="/mcqs/tagged/development-of-ear"
        >
          Development of ear -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-eye"
          to="/mcqs/tagged/development-of-eye"
        >
          Development of eye -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-salivary-glands"
          to="/mcqs/tagged/development-of-salivary-glands"
        >
          Development of salivary glands -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Biochemistry{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/vitamin-a"
          to="/mcqs/tagged/vitamin-a"
        >
          Vitamin a -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/composition-of-saliva"
          to="/mcqs/tagged/composition-of-saliva"
        >
          Composition of saliva -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/aqueous-humor"
          to="/mcqs/tagged/aqueous-humor"
        >
          Aqueous humor -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pathology{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/oral-cavity-and-salivary-glands-pathology"
          to="/mcqs/tagged/oral-cavity-and-salivary-glands-pathology"
        >
          Oral cavity and salivary glands pathology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/ears-pathology"
          to="/mcqs/tagged/ears-pathology"
        >
          Ears pathology -&gt;
        </Link>
      </p>
      <p
        className="is-size-2-desktop is-size-3-tablet is-size-4-mobile"
        style={{ padding: "0.5em 0 0 0", lineHeight: "2em" }}
      >
        <span
          className="has-text-weight-bold"
          style={{
            background: "linear-gradient(90deg, #366395, #9C9C62)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }}
        >
          Endocrinology
        </span>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Anatomy{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/hypothalamus-anatomy"
          to="/mcqs/tagged/hypothalamus-anatomy"
        >
          Hypothalamus anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pituitary-anatomy"
          to="/mcqs/tagged/pituitary-anatomy"
        >
          Pituitary anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/thyroid-anatomy"
          to="/mcqs/tagged/thyroid-anatomy"
        >
          Thyroid anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/parathyroid-anatomy"
          to="/mcqs/tagged/parathyroid-anatomy"
        >
          Parathyroid anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pancreas"
          to="/mcqs/tagged/pancreas"
        >
          Pancreas -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/adrenal-gland-anatomy"
          to="/mcqs/tagged/adrenal-gland-anatomy"
        >
          Adrenal gland anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pineal-gland-anatomy"
          to="/mcqs/tagged/pineal-gland-anatomy"
        >
          Pineal gland anatomy -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Histology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pituitary-histology"
          to="/mcqs/tagged/pituitary-histology"
        >
          Pituitary histology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/thyroid-histology"
          to="/mcqs/tagged/thyroid-histology"
        >
          Thyroid histology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/parathyroid-histology"
          to="/mcqs/tagged/parathyroid-histology"
        >
          Parathyroid histology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pancreas-histology"
          to="/mcqs/tagged/pancreas-histology"
        >
          Pancreas histology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/adrenal-gland-histology"
          to="/mcqs/tagged/adrenal-gland-histology"
        >
          Adrenal gland histology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pineal-gland-histology"
          to="/mcqs/tagged/pineal-gland-histology"
        >
          Pineal gland histology -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Embryology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-hypothalamus"
          to="/mcqs/tagged/development-of-hypothalamus"
        >
          Development of hypothalamus -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-pituitary"
          to="/mcqs/tagged/development-of-pituitary"
        >
          Development of pituitary -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-thyroid"
          to="/mcqs/tagged/development-of-thyroid"
        >
          Development of thyroid -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-parathyroid"
          to="/mcqs/tagged/development-of-parathyroid"
        >
          Development of parathyroid -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-pancreas"
          to="/mcqs/tagged/development-of-pancreas"
        >
          Development of pancreas -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-pineal-gland"
          to="/mcqs/tagged/development-of-pineal-gland"
        >
          Development of pineal gland -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-adrenal-gland"
          to="/mcqs/tagged/development-of-adrenal-gland"
        >
          Development of adrenal gland -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Physiology{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/hormone-physiology"
          to="/mcqs/tagged/hormone-physiology"
        >
          Hormone physiology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/physiology-of-hypothalamic-and-pituitary-hormones"
          to="/mcqs/tagged/physiology-of-hypothalamic-and-pituitary-hormones"
        >
          Physiology of hypothalamic and pituitary hormones -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/thyroid-hormone-physiology"
          to="/mcqs/tagged/thyroid-hormone-physiology"
        >
          Thyroid hormone physiology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/parathyroid-hormone-physiology"
          to="/mcqs/tagged/parathyroid-hormone-physiology"
        >
          Parathyroid hormone physiology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pancreatic-secretions"
          to="/mcqs/tagged/pancreatic-secretions"
        >
          Pancreatic secretions -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/adrenocortical-hormones"
          to="/mcqs/tagged/adrenocortical-hormones"
        >
          Adrenocortical hormones -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/secretions-of-the-adrenal-medulla"
          to="/mcqs/tagged/secretions-of-the-adrenal-medulla"
        >
          Secretions of the adrenal medulla -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/physiology-of-the-pineal-gland"
          to="/mcqs/tagged/physiology-of-the-pineal-gland"
        >
          Physiology of the pineal gland -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/calcium-homeostasis"
          to="/mcqs/tagged/calcium-homeostasis"
        >
          Calcium homeostasis -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Biochemistry
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/classification-of-hormones"
          to="/mcqs/tagged/classification-of-hormones"
        >
          Classification of hormones -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/hypothalamic-and-pituitary-hormones-2bd857c4-f9af-4251-bfd8-e46bed93128c"
          to="/mcqs/tagged/hypothalamic-and-pituitary-hormones-2bd857c4-f9af-4251-bfd8-e46bed93128c"
        >
          Hypothalamic and pituitary hormones -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/thyroid-and-parathyroid-hormones-biochemistry"
          to="/mcqs/tagged/thyroid-and-parathyroid-hormones-biochemistry"
        >
          Thyroid and parathyroid hormones biochemistry -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/adrenal-hormones-biochemistry"
          to="/mcqs/tagged/adrenal-hormones-biochemistry"
        >
          Adrenal hormones biochemistry -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pancreatic-hormones-biochemistry"
          to="/mcqs/tagged/pancreatic-hormones-biochemistry"
        >
          Pancreatic hormones biochemistry -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/regulation-of-blood-glucose-levels"
          to="/mcqs/tagged/regulation-of-blood-glucose-levels"
        >
          Regulation of blood glucose levels -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/metabolic-syndrome-and-obesity"
          to="/mcqs/tagged/metabolic-syndrome-and-obesity"
        >
          Metabolic syndrome and obesity -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pathology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/disorders-of-anterior-pituitary"
          to="/mcqs/tagged/disorders-of-anterior-pituitary"
        >
          Disorders of anterior pituitary -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/disorders-of-posterior-pituitary"
          to="/mcqs/tagged/disorders-of-posterior-pituitary"
        >
          Disorders of posterior pituitary -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/disorders-of-thyroid"
          to="/mcqs/tagged/disorders-of-thyroid"
        >
          Disorders of thyroid -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/disorders-of-parathyroid"
          to="/mcqs/tagged/disorders-of-parathyroid"
        >
          Disorders of parathyroid -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/disorders-of-the-endocrine-pancreas"
          to="/mcqs/tagged/disorders-of-the-endocrine-pancreas"
        >
          Disorders of the endocrine pancreas -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/disorders-of-adrenal-cortex"
          to="/mcqs/tagged/disorders-of-adrenal-cortex"
        >
          Disorders of adrenal cortex -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/disorders-of-adrenal-medulla"
          to="/mcqs/tagged/disorders-of-adrenal-medulla"
        >
          Disorders of adrenal medulla -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/multiple-endocrine-neoplasia"
          to="/mcqs/tagged/multiple-endocrine-neoplasia"
        >
          Multiple endocrine neoplasia -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pharmacology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/hypothalamic-and-pituitary-drugs"
          to="/mcqs/tagged/hypothalamic-and-pituitary-drugs"
        >
          Hypothalamic and pituitary drugs -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/drugs-for-hypo-and-hypercalcemia"
          to="/mcqs/tagged/drugs-for-hypo-and-hypercalcemia"
        >
          Drugs for hypo and hypercalcemia -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/drugs-for-hypo-and-hyperthyroidism"
          to="/mcqs/tagged/drugs-for-hypo-and-hyperthyroidism"
        >
          Drugs for hypo and hyperthyroidism -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/drugs-for-diabetes-mellitus"
          to="/mcqs/tagged/drugs-for-diabetes-mellitus"
        >
          Drugs for diabetes mellitus -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/drugs-for-adrenal-disorders"
          to="/mcqs/tagged/drugs-for-adrenal-disorders"
        >
          Drugs for adrenal disorders -&gt;
        </Link>
      </p>
      <p
        className="is-size-2-desktop is-size-3-tablet is-size-4-mobile"
        style={{ padding: "0.5em 0 0 0", lineHeight: "2em" }}
      >
        <span
          className="has-text-weight-bold"
          style={{
            background: "linear-gradient(90deg, #366395, #9C9C62)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }}
        >
          Gastrointestinal and Hepatobiliary
        </span>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Anatomy{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/anterolateral-abdominal-wall"
          to="/mcqs/tagged/anterolateral-abdominal-wall"
        >
          Anterolateral abdominal wall -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/planes-and-regions-of-the-abdomen"
          to="/mcqs/tagged/planes-and-regions-of-the-abdomen"
        >
          Planes and regions of the abdomen -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/peritoneum-and-peritoneal-reflections"
          to="/mcqs/tagged/peritoneum-and-peritoneal-reflections"
        >
          Peritoneum and peritoneal reflections -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/abdominal-viscera"
          to="/mcqs/tagged/abdominal-viscera"
        >
          Abdominal viscera -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/posterior-abdominal-wall"
          to="/mcqs/tagged/posterior-abdominal-wall"
        >
          Posterior abdominal wall -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/blood-supply-of-the-abdominal-cavity"
          to="/mcqs/tagged/blood-supply-of-the-abdominal-cavity"
        >
          Blood supply of the abdominal cavity -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Histology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-digestive-tract"
          to="/mcqs/tagged/histology-of-digestive-tract"
        >
          Histology of digestive tract -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-accessory-organs-of-digestion"
          to="/mcqs/tagged/histology-of-accessory-organs-of-digestion"
        >
          Histology of accessory organs of digestion -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Embryology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-foregut"
          to="/mcqs/tagged/development-of-foregut"
        >
          Development of foregut -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-midgut"
          to="/mcqs/tagged/development-of-midgut"
        >
          Development of midgut -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-hindgut"
          to="/mcqs/tagged/development-of-hindgut"
        >
          Development of hindgut -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Physiology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/structure-and-innervation-of-gastrointestinal-tract"
          to="/mcqs/tagged/structure-and-innervation-of-gastrointestinal-tract"
        >
          Structure and innervation of gastrointestinal tract -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/regulatory-substances-in-the-gastrointestinal-tract"
          to="/mcqs/tagged/regulatory-substances-in-the-gastrointestinal-tract"
        >
          Regulatory substances in the gastrointestinal tract -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/gastrointestinal-motility"
          to="/mcqs/tagged/gastrointestinal-motility"
        >
          Gastrointestinal motility -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/gastrointestinal-secretion"
          to="/mcqs/tagged/gastrointestinal-secretion"
        >
          Gastrointestinal secretion -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/digestion-and-absorption"
          to="/mcqs/tagged/digestion-and-absorption"
        >
          Digestion and absorption -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/liver-and-gallbladder-secretions"
          to="/mcqs/tagged/liver-and-gallbladder-secretions"
        >
          Liver and gallbladder secretions -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/exocrine-pancreas-secretions"
          to="/mcqs/tagged/exocrine-pancreas-secretions"
        >
          Exocrine pancreas secretions -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Biochemistry
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/bioenergetics-and-oxidative-phosphorylation"
          to="/mcqs/tagged/bioenergetics-and-oxidative-phosphorylation"
        >
          Bioenergetics and oxidative phosphorylation -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/metabolism-of-carbohydrates"
          to="/mcqs/tagged/metabolism-of-carbohydrates"
        >
          Metabolism of carbohydrates -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/metabolism-of-proteins"
          to="/mcqs/tagged/metabolism-of-proteins"
        >
          Metabolism of proteins -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/metabolism-of-lipids"
          to="/mcqs/tagged/metabolism-of-lipids"
        >
          Metabolism of lipids -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/nucleic-acid-metabolism"
          to="/mcqs/tagged/nucleic-acid-metabolism"
        >
          Nucleic acid metabolism -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/bilirubin-metabolism"
          to="/mcqs/tagged/bilirubin-metabolism"
        >
          Bilirubin metabolism -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/disorders-of-malnutrition"
          to="/mcqs/tagged/disorders-of-malnutrition"
        >
          Disorders of malnutrition -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pathology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/congenital-anomalies-of-the-gastrointestinal-tract"
          to="/mcqs/tagged/congenital-anomalies-of-the-gastrointestinal-tract"
        >
          Congenital anomalies of the gastrointestinal tract -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/oral-cavity-and-salivary-glands-pathology"
          to="/mcqs/tagged/oral-cavity-and-salivary-glands-pathology"
        >
          Oral cavity and salivary glands pathology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/esophagus"
          to="/mcqs/tagged/esophagus"
        >
          Esophagus -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/stomach"
          to="/mcqs/tagged/stomach"
        >
          Stomach -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/small-intestine-and-colon"
          to="/mcqs/tagged/small-intestine-and-colon"
        >
          Small intestine and colon -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/peritoneal-cavity"
          to="/mcqs/tagged/peritoneal-cavity"
        >
          Peritoneal cavity -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/liver-and-gallbladder"
          to="/mcqs/tagged/liver-and-gallbladder"
        >
          Liver and gallbladder -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/exocrine-pancreas"
          to="/mcqs/tagged/exocrine-pancreas"
        >
          Exocrine pancreas -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/infectious-agents-of-diarrhea-and-dysentery"
          to="/mcqs/tagged/infectious-agents-of-diarrhea-and-dysentery"
        >
          Infectious agents of diarrhea and dysentery -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/gram-negative-rods-related-to-enteric-tract"
          to="/mcqs/tagged/gram-negative-rods-related-to-enteric-tract"
        >
          Gram negative rods related to enteric tract -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pharmacology{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/drugs-for-peptic-ulcer-disease"
          to="/mcqs/tagged/drugs-for-peptic-ulcer-disease"
        >
          Drugs for peptic ulcer disease -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/drugs-for-inflammatory-bowel-disease"
          to="/mcqs/tagged/drugs-for-inflammatory-bowel-disease"
        >
          Drugs for inflammatory bowel disease -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/antidiarrheals"
          to="/mcqs/tagged/antidiarrheals"
        >
          Antidiarrheals -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/antiemetics"
          to="/mcqs/tagged/antiemetics"
        >
          Antiemetics -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/laxatives"
          to="/mcqs/tagged/laxatives"
        >
          Laxatives -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/drugs-for-hepatitis"
          to="/mcqs/tagged/drugs-for-hepatitis"
        >
          Drugs for hepatitis -&gt;
        </Link>
      </p>
      <p
        className="is-size-2-desktop is-size-3-tablet is-size-4-mobile"
        style={{ padding: "0.5em 0 0 0", lineHeight: "2em" }}
      >
        <span
          className="has-text-weight-bold"
          style={{
            background: "linear-gradient(90deg, #366395, #9C9C62)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }}
        >
          Renal
        </span>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        {" "}
        Anatomy, Histology, and Embryology{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/anatomy-of-kidneys"
          to="/mcqs/tagged/anatomy-of-kidneys"
        >
          Anatomy of kidneys -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/anatomy-of-ureters-urinary-bladder-urethra"
          to="/mcqs/tagged/anatomy-of-ureters-urinary-bladder-urethra"
        >
          Anatomy of ureters urinary bladder urethra -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-nephron"
          to="/mcqs/tagged/histology-of-nephron"
        >
          Histology of nephron -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-the-urinary-tract"
          to="/mcqs/tagged/histology-of-the-urinary-tract"
        >
          Histology of the urinary tract -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-the-urinary-system"
          to="/mcqs/tagged/development-of-the-urinary-system"
        >
          Development of the urinary system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/congenital-anomalies-of-the-urinary-tract"
          to="/mcqs/tagged/congenital-anomalies-of-the-urinary-tract"
        >
          Congenital anomalies of the urinary tract -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Biochemistry{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/nucleic-acid-metabolism"
          to="/mcqs/tagged/nucleic-acid-metabolism"
        >
          Nucleic acid metabolism -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/urinalysis"
          to="/mcqs/tagged/urinalysis"
        >
          Urinalysis -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/renal-function-tests"
          to="/mcqs/tagged/renal-function-tests"
        >
          Renal function tests -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/acid-base-balance-mechanism"
          to="/mcqs/tagged/acid-base-balance-mechanism"
        >
          Acid base balance mechanism -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/acid-base-disorders"
          to="/mcqs/tagged/acid-base-disorders"
        >
          Acid base disorders -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Physiology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/physiological-functions-of-kidney"
          to="/mcqs/tagged/physiological-functions-of-kidney"
        >
          Physiological functions of kidney -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/glomerular-filtration-renal-blood-flow-and-their-control"
          to="/mcqs/tagged/glomerular-filtration-renal-blood-flow-and-their-control"
        >
          Glomerular filtration renal blood flow and their control -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/tubular-processing-of-glomerular-filtrate"
          to="/mcqs/tagged/tubular-processing-of-glomerular-filtrate"
        >
          Tubular processing of glomerular filtrate -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/regulation-of-fluids-and-electrolytes"
          to="/mcqs/tagged/regulation-of-fluids-and-electrolytes"
        >
          Regulation of fluids and electrolytes -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/renin-angiotensin-aldosterone-system"
          to="/mcqs/tagged/renin-angiotensin-aldosterone-system"
        >
          Renin angiotensin aldosterone system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/renal-regulation-of-calcium-phosphate-and-magnesium"
          to="/mcqs/tagged/renal-regulation-of-calcium-phosphate-and-magnesium"
        >
          Renal regulation of calcium phosphate and magnesium -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/micturition"
          to="/mcqs/tagged/micturition"
        >
          Micturition -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/acid-base-balance-mechanism"
          to="/mcqs/tagged/acid-base-balance-mechanism"
        >
          Acid base balance mechanism -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/acid-base-disorders"
          to="/mcqs/tagged/acid-base-disorders"
        >
          Acid base disorders -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/fluid-compartments"
          to="/mcqs/tagged/fluid-compartments"
        >
          Fluid compartments -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pathology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/congenital-anomalies-of-the-urinary-tract"
          to="/mcqs/tagged/congenital-anomalies-of-the-urinary-tract"
        >
          Congenital anomalies of the urinary tract -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/glomerular-disorders"
          to="/mcqs/tagged/glomerular-disorders"
        >
          Glomerular disorders -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/renal-failure"
          to="/mcqs/tagged/renal-failure"
        >
          Renal failure -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/acute-interstitial-nephritis"
          to="/mcqs/tagged/acute-interstitial-nephritis"
        >
          Acute interstitial nephritis -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/acute-tubular-necrosis"
          to="/mcqs/tagged/acute-tubular-necrosis"
        >
          Acute tubular necrosis -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/nephrolithiasis"
          to="/mcqs/tagged/nephrolithiasis"
        >
          Nephrolithiasis -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/infections-of-the-urinary-tract"
          to="/mcqs/tagged/infections-of-the-urinary-tract"
        >
          Infections of the urinary tract -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/cystic-disorders-of-kidney"
          to="/mcqs/tagged/cystic-disorders-of-kidney"
        >
          Cystic disorders of kidney -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/renal-vascular-disorders"
          to="/mcqs/tagged/renal-vascular-disorders"
        >
          Renal vascular disorders -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/renal-transplant"
          to="/mcqs/tagged/renal-transplant"
        >
          Renal transplant -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/renal-tubular-defects-and-acidosis"
          to="/mcqs/tagged/renal-tubular-defects-and-acidosis"
        >
          Renal tubular defects and acidosis -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pharmacology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/diuretics"
          to="/mcqs/tagged/diuretics"
        >
          Diuretics -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/antihypertensives"
          to="/mcqs/tagged/antihypertensives"
        >
          Antihypertensives -&gt;
        </Link>
      </p>
      <p
        className="is-size-2-desktop is-size-3-tablet is-size-4-mobile"
        style={{ padding: "0.5em 0 0 0", lineHeight: "2em" }}
      >
        <span
          className="has-text-weight-bold"
          style={{
            background: "linear-gradient(90deg, #366395, #9C9C62)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }}
        >
          Reproductive
        </span>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Anatomy{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pelvic-cavity-and-girdle"
          to="/mcqs/tagged/pelvic-cavity-and-girdle"
        >
          Pelvic cavity and girdle -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/nerves-and-vessels-of-pelvis-and-perineum"
          to="/mcqs/tagged/nerves-and-vessels-of-pelvis-and-perineum"
        >
          Nerves and vessels of pelvis and perineum -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pelvic-viscera"
          to="/mcqs/tagged/pelvic-viscera"
        >
          Pelvic viscera -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/male-genital-organs"
          to="/mcqs/tagged/male-genital-organs"
        >
          Male genital organs -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/female-genital-organs"
          to="/mcqs/tagged/female-genital-organs"
        >
          Female genital organs -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/perineum"
          to="/mcqs/tagged/perineum"
        >
          Perineum -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/anal-canal-and-ischiorectal-fossa"
          to="/mcqs/tagged/anal-canal-and-ischiorectal-fossa"
        >
          Anal canal and ischiorectal fossa -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/anatomy-of-breast"
          to="/mcqs/tagged/anatomy-of-breast"
        >
          Anatomy of breast -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/lumbosacral-vertebrae-anatomy"
          to="/mcqs/tagged/lumbosacral-vertebrae-anatomy"
        >
          Lumbosacral vertebrae anatomy -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/structure-of-the-placenta"
          to="/mcqs/tagged/structure-of-the-placenta"
        >
          Structure of the placenta -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Histology{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-male-genital-system"
          to="/mcqs/tagged/histology-of-male-genital-system"
        >
          Histology of male genital system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-female-genital-system"
          to="/mcqs/tagged/histology-of-female-genital-system"
        >
          Histology of female genital system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/histology-of-mammary-glands"
          to="/mcqs/tagged/histology-of-mammary-glands"
        >
          Histology of mammary glands -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Embryology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-male-genital-system"
          to="/mcqs/tagged/development-of-male-genital-system"
        >
          Development of male genital system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/development-of-female-genital-system"
          to="/mcqs/tagged/development-of-female-genital-system"
        >
          Development of female genital system -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Physiology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/physiology-of-male-reproduction"
          to="/mcqs/tagged/physiology-of-male-reproduction"
        >
          Physiology of male reproduction -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/physiology-of-female-reproduction"
          to="/mcqs/tagged/physiology-of-female-reproduction"
        >
          Physiology of female reproduction -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/menstrual-cycle-and-ovulation"
          to="/mcqs/tagged/menstrual-cycle-and-ovulation"
        >
          Menstrual cycle and ovulation -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/lactation"
          to="/mcqs/tagged/lactation"
        >
          Lactation -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/infertility"
          to="/mcqs/tagged/infertility"
        >
          Infertility -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/pregnancy"
          to="/mcqs/tagged/pregnancy"
        >
          Pregnancy -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Biochemistry{" "}
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/synthesis-of-steroid-hormones"
          to="/mcqs/tagged/synthesis-of-steroid-hormones"
        >
          Synthesis of steroid hormones -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/male-and-female-hormones"
          to="/mcqs/tagged/male-and-female-hormones"
        >
          Male and female hormones -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/biochemical-changes-during-menarche"
          to="/mcqs/tagged/biochemical-changes-during-menarche"
        >
          Biochemical changes during menarche -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/biochemical-changes-during-menopause"
          to="/mcqs/tagged/biochemical-changes-during-menopause"
        >
          Biochemical changes during menopause -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/biochemical-needs-during-pregnancy-and-lactation"
          to="/mcqs/tagged/biochemical-needs-during-pregnancy-and-lactation"
        >
          Biochemical needs during pregnancy and lactation -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/placental-hormones"
          to="/mcqs/tagged/placental-hormones"
        >
          Placental hormones -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/metabolic-needs-during-adolescence"
          to="/mcqs/tagged/metabolic-needs-during-adolescence"
        >
          Metabolic needs during adolescence -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pathology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/sex-chromosome-disorders"
          to="/mcqs/tagged/sex-chromosome-disorders"
        >
          Sex chromosome disorders -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/congenital-disorders-of-the-female-reproductive-system"
          to="/mcqs/tagged/congenital-disorders-of-the-female-reproductive-system"
        >
          Congenital disorders of the female reproductive system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/menstrual-disorders"
          to="/mcqs/tagged/menstrual-disorders"
        >
          Menstrual disorders -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/disorders-of-female-genital-system"
          to="/mcqs/tagged/disorders-of-female-genital-system"
        >
          Disorders of female genital system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/gestational-and-placental-disorders"
          to="/mcqs/tagged/gestational-and-placental-disorders"
        >
          Gestational and placental disorders -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/congenital-disorders-of-the-male-reproductive-system"
          to="/mcqs/tagged/congenital-disorders-of-the-male-reproductive-system"
        >
          Congenital disorders of the male reproductive system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/disorders-of-male-genital-system"
          to="/mcqs/tagged/disorders-of-male-genital-system"
        >
          Disorders of male genital system -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/inflammatory-breast-conditions"
          to="/mcqs/tagged/inflammatory-breast-conditions"
        >
          Inflammatory breast conditions -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/benign-tumors-of-the-breast"
          to="/mcqs/tagged/benign-tumors-of-the-breast"
        >
          Benign tumors of the breast -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/prostate-pathology"
          to="/mcqs/tagged/prostate-pathology"
        >
          Prostate pathology -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/breast-cancer"
          to="/mcqs/tagged/breast-cancer"
        >
          Breast cancer -&gt;
        </Link>
      </p>
      <p
        className="is-uppercase has-text-weight-bold global-text"
        style={{ paddingTop: "1em", letterSpacing: "0.1em" }}
      >
        Pharmacology
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/androgens-and-anabolic-steroids"
          to="/mcqs/tagged/androgens-and-anabolic-steroids"
        >
          Androgens and anabolic steroids -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/antiandrogens-and-androgen-suppression"
          to="/mcqs/tagged/antiandrogens-and-androgen-suppression"
        >
          Antiandrogens and androgen suppression -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/estrogens-and-antiestrogens"
          to="/mcqs/tagged/estrogens-and-antiestrogens"
        >
          Estrogens and antiestrogens -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/progestins-and-antiprogestins"
          to="/mcqs/tagged/progestins-and-antiprogestins"
        >
          Progestins and antiprogestins -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/hormonal-contraception"
          to="/mcqs/tagged/hormonal-contraception"
        >
          Hormonal contraception -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/drugs-altering-uterine-motility"
          to="/mcqs/tagged/drugs-altering-uterine-motility"
        >
          Drugs altering uterine motility -&gt;
        </Link>
      </p>
      <p className="has-text-weight-bold">
        <Link
          data-savepage-href="/smart-sets/prolactin-inhibiting-factors"
          to="/mcqs/tagged/prolactin-inhibiting-factors"
        >
          Prolactin inhibiting factors -&gt;
        </Link>
      </p>
    </div>
  </div>
  
  );
}


function TopicMCQS(props) {
  
  return (
    <>
    <Layout>
      <Seo title="MCQS" />
        <Foo
         />
    </Layout>
    </>
  )
}

export default TopicMCQS
